.header {
  width: 100%;
}

.headerContent {
  max-width: var(--view-max-width);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 8px var(--side-margin-mobile) 0 var(--side-margin-mobile);
}

.logoWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.logo {
  font-family: "Nanum Pen Script", Georgia, 'Times New Roman', Times, serif;
  font-size: 42px;
}

.menuAnchor {
  padding-inline-end: 18px;
}

.lineWrapper {
  max-width: calc(var(--view-max-width) + 350px);
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.lineWrapper svg {
  text-align: center;
  width: 600px;
  min-width: 600px;
}
