.section {
  width: 100%;
}

.textSection {
  padding-inline-end: var(--side-margin-mobile);
  max-width: 500px;
  padding-top: 40px;
}

.previewSection {
  padding-inline-end: var(--side-margin-mobile);
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
