.section {
  width: 100%;
}

.textSection {
  padding-inline-end: var(--side-margin-mobile);
  max-width: 500px;
  padding-top: 40px;
}

.pictureSection {
  width: 70vw;
  margin: 0 auto;
  padding-top: 40px;
}

@media (min-width: 768px) {
  .section {
    display: flex;
    justify-content: space-between;
  }

  .textSection {
    order: 1;
  }

  .pictureSection {
    order: 2;
    width: 400px;
    margin: 0 0;
    padding-top: 0;
  }
}
