.box {
  width: 300px;
  margin: 0 50px 50px 50px;
  display: block;
  text-decoration: none;
}

.coverWrapper {
  border: 3px solid black;
  overflow: hidden;
  width: 300px;
  height: 200px;
}

.cover {
  width: 300px;
  height: 200px;
  object-fit: cover;
}

.title {
  font-size: 1.5em;
}

.title:hover {
  color: var(--blue-primary);
}

.readMore {
  color: var(--blue-primary);
}
