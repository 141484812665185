.footer {
  width: 100%;
}

.footerContent {
  max-width: var(--view-max-width);
  align-items: center;
  margin: 0 auto;
  padding: 8px var(--side-margin-mobile);
}

.lineWrapper {
  max-width: calc(var(--view-max-width) + 350px);
  margin: 0 auto;
}

.separatorWrapper {
  text-align: center;
}

.separatorWrapper > svg {
  width: 250px;
}

.link {
  margin: 0 10px;
}
