.anchorNoStyle,
.anchorNoStyle:link,
.anchorNoStyle:visited {
  text-decoration: none;
  color: inherit;
}

.anchorUnderlineHover:hover {
  text-decoration: underline;
}

.limitWidth {
  width: 100%;
  max-width: var(--view-max-width);
  margin: 0 auto;
}

.sidePadding {
  padding-inline-start: var(--side-margin-mobile);
  padding-inline-end: var(--side-margin-mobile);
}
